<template>
    <div id="index">
        <div class="logo">
            <img src="../assets/images/logo.png" alt="">
        </div>
        <div class="address_box" @click="quit">
            <img src="../assets/images/addressWhite.png" alt=""
                 style="width: 23px; height: 28px;">
            <span v-if="$store.state.user.hukou == 1">农村</span>
            <span v-if="$store.state.user.hukou == 2">城市</span>
        </div>
        <div class="con">
            <div class="index-content">
                <router-link v-for="(item,index) in line.slice(0,2)"
                             :key="'line1' + index" :to="item.to" :style="{'pointer-events': check(item.name)}">
                    <div class="bbb">
<!--                        <div :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" v-html="item.name" class="aaa"></div>-->
                        <!--                    <div :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" v-html="item.introduce" class="ccc"></div>-->
                        <img :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" :src="item.img" alt="">
                    </div>
                </router-link>
            </div>
            <div class="index-content content1">
                <router-link v-for="(item,index) in line.slice(2,4)"
                             :key="'line1' + index" :to="item.to" :style="{'pointer-events': check(item.name)}">
                    <div class="bbb">
<!--                        <div :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" v-html="item.name" class="aaa"></div>-->
                        <!--                    <div :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" v-html="item.introduce" class="ccc"></div>-->
                        <img :class="{disabled:!$store.state.mainMenus.includes(item.name),img:true}" :src="item.img" alt="" >
                    </div>
                </router-link>
            </div>
        </div>

<!--        <div class="all_right">-->
<!--            <el-popover-->
<!--                placement="top"-->
<!--                width="160"-->
<!--                v-model="logoutShow">-->
<!--                <p>确定退出登录？</p>-->
<!--                <div style="text-align: right; margin: 0">-->
<!--                    <el-button size="mini" type="text" @click="logoutShow = false">取消</el-button>-->
<!--                    <el-button type="primary" size="mini" @click="logout">确定</el-button>-->
<!--                </div>-->
<!--                <button slot="reference" class="all_right_top">-->
<!--                    <img src="../assets/images/user_red.png" alt="">-->
<!--                    <p>退出登录</p>-->
<!--                </button>-->
<!--            </el-popover>-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
    name: "Index",
    data() {
        return {

            logoutShow: false,
            line: [
                {
                    img: require('@/assets/images/index_icon1.png'),
                    name: '患者管理',
                    // introduce:'患者信息管理',
                    to: 'PatientManagement'
                },


                {
                    img: require("@/assets/images/index_icon2.png"),
                    name: '评估测试',
                    // introduce:'多种题型测试',
                    to: '/Assess'
                },

                //line 2
                {
                    img: require("@/assets/images/index_icon5.png"),
                    name: '报告中心',
                    // introduce:'报告随时查看',
                    to: '/ReportCenter'
                },
                {
                    img: require("@/assets/images/index_icon8.png"),
                    name: '系统设置',
                    // introduce:'管理员系统配置',
                    to: '/SystemSetting'
                },

            ]
        };
    },
    computed: {
        check: function () {
            return (name) => {
                return this.$store.state.mainMenus.includes(name) ? 'all' : 'none'
            }
        }
    },
    watch: {
        /*'$store.state.mainMenus':(newVal)=>{
            console.log('mainMenus change',newVal)
        }*/
    },
    async mounted() {
        console.log('用户类型', sessionStorage.getItem('usertype'))

        if (sessionStorage.getItem('usertype') !== 'center') {
            this.$router.push('/TerminalLogin')
        }
        if (!sessionStorage.getItem('usertype')) {
            this.$router.push('/Login')
        }
        this.getRules().then()
    },
    methods: {
        quit() {
            if(!this.$store.state.user.hukouclick) {
                if(this.$store.state.user.hukou == 1) {
                    this.$message({
                        type: 'info',
                        message: '当前账号只支持农村版'
                    });
                } else {
                    this.$message({
                        type: 'info',
                        message: '当前账号只支持城市版'
                    });
                }
            } else {
                this.$router.push('/hukou');
            }
        },
        logout() {
            this.$store.commit('setUserInfo', {data: '', status: false});
            this.$router.push('/Login');
        },
        getRules() {
            let rule = new Promise((resolve, reject) => {
                //获取权限列表
                this.$store.dispatch('GetAuth')
            })
            return rule
        },


    },
}
</script>

<style scoped lang="scss">
.address_box {
    width: 135px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #62B5FB;
    border-radius: 2px;
    position: fixed;
    top: 10%;
    right: 10%;
    cursor: pointer;
    span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 10px;
    }
}
button {
    border: none;
}
.bbb {
    position: relative;
}
.aaa {
    height: 33px!important;
    z-index: 100;
    position: absolute;
    bottom: 10%;
    left: 38%;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
}
#index {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/login_BG.png') no-repeat;
    background-size: 100% 100%;
}

.logo {
    width: 500px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    position: fixed;
    top:10vh;
    left: 4%;
    img{
        width: 338px;
        height: 85px;
        margin-left: 100px;
    }
}
.con {
    position: fixed;
    top:24vh;
    left: 25%;
}
.index-content {
    width: 1000px;
    height: 320px;
    display: flex;
    justify-content: space-between;
}

.content1 {
    margin-top: 30px;
}

.content1 a {
    margin-right: 20px !important;
}
.index-content .img {
    display: block;
    width: 443px;
    height: 304px;
    transition: .3s all ease-in-out;
}

.index-content a {
    margin-right: 20px;
    //width: 30%;
    //height: 22%;
}

.all_right_top p::after {
    border-top: 8px solid #2FB2FB;
}
::v-deep .el-button--primary {
    background-color: #2FB2FB;
}
::v-deep .el-button--text {
    color: #2FB2FB;
}
.disabled {
    filter: grayscale(100%);
}
</style>
